.back-icon,
.info-icon {
  path {
    stroke: white;
  }
}
.download-icon path {
  stroke: #4280ff;
}

.viewlist-btn-container {
  position: absolute;
  right: 0;
  top: -4rem;
}
