.modal-container {
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;

  .agents-list {
    overflow-y: auto;
    max-height: 60vh;
    margin-bottom: 10px;

    .agent-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      padding: 12px 20px;
      cursor: pointer;
      transition: background 0.2s ease-in-out;

      &:hover {
        background: #dde8ff80;
      }

      .agent-checkbox {
        height: 16px;
        width: 16px;
        border-radius: 4px;
        border: 1px solid #d1d5db;
        accent-color: #2563eb;
      }

      .agent-info {
        display: flex;
        flex-direction: column;
        .agent-details {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          .agent-icon {
            color: #555555;
          }
          .agent-name {
            font-size: 0.875rem;
            font-weight: 500;
            color: #181818;
            margin: 0;
          }
        }
        .agent-description {
          font-size: 0.8rem;
          color: #555555;
          margin-top: 4px;
        }
      }
    }
  }
}

.workflow-container {
  display: flex;
  height: 100vh;

  .multi-agent-sidebar {
    width: 250px;
    padding-left: 16px;
    box-shadow: -10px 0px 20px -5px rgba(0, 0, 0, 0.15);
    // display: flex;
    // flex-direction: column;
    height: 90vh;
  }

  .multi-agent-sidebar > div:first-child {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
  }

  .multi-agent-sidebar > div:last-child {
    margin-top: 10px;
    border-top: 1px solid #ccd2de;
    padding: 12px 4px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    // position: sticky;
    // bottom: 0;
  }

  .canvas {
    flex: 1;
    position: relative;
    overflow: hidden;
    cursor: crosshair;

    .svg-container {
      position: absolute;
      inset: 0;
      z-index: 1;

      svg {
        width: 100%;
        height: 100%;

        .connection-path {
          transition: all 0.3s ease-in-out;
        }

        .dashed-path {
          stroke: #5aa552;
          stroke-width: 1;
          stroke-dasharray: 3, 3;
          fill: none;
        }
      }
    }

    .nodes-container {
      position: absolute;
      inset: 0;
      z-index: 2;
    }
  }
}

//   ****************

.schedule-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.schedule-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #d1d5db;
  margin-bottom: 0.25rem;
}

.schedule-select,
.schedule-input {
  width: 100%;
  color: #1a1d23;
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  outline: none;
  // border: none;
  &:focus {
    box-shadow: 0 0 0 2px #3b82f6;
  }
}

.days-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
}

.day-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox {
  border: 1px solid #6b7280;
  accent-color: #3b82f6;
}

.day-label {
  font-size: 0.875rem;
  color: #1a1d23;
}

.event-conditions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgba(55, 65, 81, 0.5);
  padding: 0.75rem;
  border-radius: 0.5rem;
}

.icon {
  margin-left: 0.5rem;
  display: inline;
}

.small-input {
  width: 5rem;
}

// **********************

.node-container {
  width: 12rem;
  border-radius: 8px;
  border: 1px solid #ccd2de;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &.connecting {
    border: 2px solid #3b82f6;
  }

  .node-header {
    padding: 0.7rem;
    cursor: pointer;
    position: relative;

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        .node-name {
          font-size: 12px;
          font-weight: 500;
          color: #333333;
          text-transform: capitalize;
        }
        .icon-component {
          color: #555555;
        }
      }
    }
  }

  .node-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem;
    background-color: #f8fafe;
    border-top: 1px solid #ccd2de;
    border-radius: 0 0 0.5rem 0.5rem;

    .input-button,
    .connect-button {
      font-size: 0.75rem;
      font-weight: 500;
      padding: 0.25rem 0.5rem;
      color: #60a5fa;
      &:hover {
        color: #93c5fd;
      }
    }

    .connect-button {
      color: #5aa552;
      &:hover {
        color: #6ee7b7;
      }
    }
  }

  .modal-content {
    background-color: #2d2d2d;
    border-radius: 0.5rem;
    padding: 1.5rem;
    width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;

    .checkbox-group {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      input[type="checkbox"] {
        border-color: #374151;
        &:checked {
          color: #3b82f6;
        }
      }

      label {
        font-size: 0.875rem;
        color: #d1d5db;
      }
    }
  }
}

.form-label {
  color: #1a1d23;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.close {
  svg {
    height: 0.7em;
  }
}

.submit-button {
  background-color: #ff5e00bf;
  color: white;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 8px;
  :hover {
    background-color: #e65500;
    transition: all 0.3s ease-in-out;
  }
}
