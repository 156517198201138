/* .modal.show {
  z-index: 1200;
} */

.modal-dialog {
  display: flex;
  justify-content: center;
}

.modal-content {
  width: auto !important;
}