.designed-modal{
    background: url(./res.png)  no-repeat  !important;  
    color: #f0f0f0;
  max-width: 380px !important;
  margin-top: -5px;
  padding: 8px;
  font-size: 14px;
  }
  
  .custom-btn {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
    font-size: 12px;
    padding: 1em 2.7em;
    font-weight: 200;
    background: #1f2937;
    color: white;
    border: none;
    position: relative;
    overflow: hidden;
    border-radius: 0.6em;
    cursor: pointer;
    margin-top: -10px;
  }
  
  .gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0.6em;
    margin-top: -0.25em;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.3)
    );
  }
  
  .label {
    position: relative;
    top: -1px;
  }
  
  .transition {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 500ms;
    background-color: #219ebc;
    border-radius: 9999px;
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .custom-btn:hover .transition {
    width: 14em;
    height: 14em;
  }
  
  .custom-btn:active {
    transform: scale(0.97);
  }
  