.calling {
  background-color: yellow;
  margin: 1%;
  width: 5%;
  color: black;
}
.connected {
  background-color: green;
  width: 5%;
  margin: 1%;
  color: white;
}

.disconnected {
  width: 5%;
  height: 10px;
  background-color: orange;
  color: white;
}
.failed {
  width: 5%;
  background-color: red;
  margin: 1%;
  color: white;
}
