.width50{
    width: 50% !important;
}
.react-datepicker--time-only{
    left: 6px !important;
}
.react-datepicker__time-container{
    width: 130px !important;
}
.react-datepicker__time-list{
    width: 100% !important;

}
.react-datepicker__time-box{
    width: 122px !important;
}
