

:root{
    --button-orange:#FF9466; /* Default color */
    --vodex-orange:#ff4d00; /* Default color */
    --white-label-color:#3A416F; /* Default color */
}
body {
    color: darkslategray;
    overflow: hidden;
}

.popup-modal-content {
    width     : 300px !important;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.popup-title {
    height          : 40px;
    position        : absolute;
    width           : 100%;
    left            : 0px;
    background-color: whitesmoke;
    top             : 0px;
    text-align      : center;
    padding-top     : 5px;
    color           : black;
}

.popup-content {
    margin-top : 40px;
    padding-top: 10px;
    width      : 100%;
    text-align : center;
}

.popup-modal {
    height: 70px;
}

.left-margin {
    margin-left: 10px;
}

.toast-wrapper {
    position: fixed;
    top     : 80px;
    right   : 30px;
    z-index: 99999 !important;
}

.toast {
    opacity: 0.95 !important;
    width: 250px;
}

.toast-header {
    color: dodgerblue !important;
}

.card-bottom-list {
    max-height: 303px;
}

.bg-login-image {
    /* background: url("./assets1/login.png") !important; */
    background-position: center !important;
    background-size: cover !important;
}

.bg-gradient-primary-green {
    background-image: linear-gradient(180deg,#0e1e24 10%, #00c6b6 100%);
    background-size: cover;
}

.icon-green {
    color: seagreen;
}

.bg-custom-dark {
    background-color: #0e1e24;
}

.dark-breadcrumb {
    background-color: #0e1e24 !important;
    margin-top: 15px !important;
}

li a {
    color: cadetblue;
}

.cadet {
    color: cadetblue;
}

.text-green {
    color: cadetblue !important;
    font-family: sans-serif !important;
}

.toggle-area {
    display:none;
}

.collapsed {
    display:block;
}

.toggle-button {
    background-color: #0e1e24 !important;
    border-color: #0e1e24 !important;
    width: 40px;
    color: seagreen !important;
}


@media (max-width: 380px) {
    .collapsed {
        display:none !important;
    }

    .toggle-area {
        position: fixed;
        bottom: 2%;
        left:2%;
        z-index: 200 !important;
        display: block;
    }
}

.hidden {
    visibility: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.w-200{
    width: 200px !important;
}

@font-face {
    font-family: myFirstFont;
    src: url(./panton-rust-extrabold-base.ttf);
}
  
.patron {
    font-family: myFirstFont;
}
.f-18{
    font-size: 18px;
}



.required-star span {
    color: red;
    padding: 0 0 0 5px;
    font-size: 14px;
  }

  .loading {
    font-weight: bold;
    display:inline-block;
    font-family: monospace;
    font-size: 16px;
    clip-path: inset(0 3ch 0 0);
    animation: l 1s steps(4) infinite;  
  }
  
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }



  .overlay1 {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }
  
  .overlay-content1 {
    position: relative;
    top: 5%;
    width: 85%;
    margin: auto;
    text-align: center;
    margin-top: 30px;
    height: 78vh;
  }
  
  .overlay-content1 .text-area {
    width: 90%;
    font-size: 18px;
padding: 15px; 
border-radius: 5px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  .overlay1 a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .overlay1 a:hover, .overlay1 a:focus {
    color: #f1f1f1;
  }
  
  .overlay1 .closebtn {
    position: absolute;
    top: 2px;
    right: 45px;
    font-size: 60px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay1 a {font-size: 20px}
    .overlay1 .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }

 
  .icon1 {
    position: absolute;
    top: 40px;
    right: 45px; 
    font-size: 24px;
    cursor: pointer;
  }
.fixed-col-container.infinite-scroll-component {
  overflow: inherit !important;
}
  #scrollableDiv>div {
    flex: 1;
  }

  .scroll-container {
    position: relative;
}

.scroll-container .top {
    width: calc(100% - 17px);
    position: absolute;
    background: #fff;
    z-index: 9;
}

.scroll-container .left {
    width: 15px;
    position: absolute;
    height: calc(100% - 17px);
    background: #fff;
    z-index: 9;
}

.scroll-container .right {
    width: 5px;
    position: absolute;
    height: calc(100% - 0px);
    background: #fff;
    z-index: 9;
}

.scroll-container .all-scroll {
    scroll-padding: 15px 15px 0px 15px;
    /* overflow: scroll; */
    position: relative;
    height: calc(100vh - 170px);
    width: 100%;
}

.completed{
    background-color: #4ed66e;
    color:white;
}
.stopped{
    background-color:rgb(255 24 24);
    color:white;
}
.in-progress{
    background-color: #ffc107;
    color:white;
}
.w-5 {
  width: 5% !important;
}
.w-10 {
    width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
    width: 20% !important;
}
.w-25 {
  width: 25% !important;
}

.w-30{
    width: 30% !important;
}

.w-40{
    width: 40% !important;
}
.w-50{
    width: 50% !important;
}
.w-60{
    width: 60% !important;
}
.w-70{
    width: 70% !important;
}
.w-80{
    width: 80% !important;
}

.w-90{
    width: 90% !important;
}

.w-100{
    width: 100% !important;
}
@media (max-width: 770px) {
    .display-mobile-none{
        display: none;
    }
    .display-mobile{
        margin-left: -15px !important;
    }
}
.white-label-image {
  height: 25px !important;
  width: 120px !important;
}

.white-label-image-lg {
  height: 40px;
  width: 200px;
}
.cursor-pointer {
    cursor: pointer;
}

.custom-button-icon{
    margin: 2px;
    background-color: #e9ecefda;
    padding: 7px 12px;
    border-radius: 5px !important;
    color: #000;
    max-height: 38px;
    text-overflow: ellipsis;
}
.custom-button-icon-100px{
    margin: 2px;
    background-color: #e9ecefda;
    padding: 7px 12px;
    border-radius: 5px !important;
    color: #000;
    min-width: 100px !important;
}

.custom-button-icon-100px:hover {
    background-color: #c0c4c9da;
}
.custom-button-icon:hover {
    background-color: #c0c4c9da;
}
.chip {
    margin-bottom: -3px !important;
}
.custom-textarea{
    border: 1px solid #ced4da !important;
    width: 335px;
    border-radius: 8px;
    padding: 8px;

}

textarea:focus {
    outline: 1px solid #959ba0; /* Add your desired border style here */
}

.no-outline{
    border: none !important;
    background-color: none !important;
    background: none !important ;
}
.input-group{
    margin-top: 10px !important;
}
.transparent-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: rgba(255, 0, 0, 0.5); /* Adjust the color and opacity as needed */
    color: white;
    text-align: center;
    line-height: 30px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    z-index: 900; /* Set a high z-index to ensure it appears above other elements */
  }

  .transparent-alert-black {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: orange; /* Adjust the color and opacity as needed */
    color: black;
    text-align: center;
    line-height: 30px;
    z-index: 900; /* Set a high z-index to ensure it appears above other elements */
  }

  .transparent-alert-black p {
    color: black;
    margin-top: 15px;
  }
  .nav-item .nav-link{
    padding: 10px !important
  }

  .centered-div {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
}
.header-buttons {
    position: absolute;
    right: 10px;
    top: 8px;
}


/* Webkit (Chrome, Safari) */
::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f0f0f0; 
  }


  /* Firefox */
  * {
    scrollbar-color: rgb(58, 65, 111);
    scrollbar-width: thin;
  }
  
  /* IE/Edge */
  * {
    -ms-overflow-style: none;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgb(58, 65, 111);
  }
  

  .tableRightCol{
    text-align: center  ;
}
.toolTip{
display: inline;
color: red !important;
}
.no-padding {
    padding:7px 10px !important;
    max-height: 32px !important;
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflow text */
    white-space: nowrap; /* Prevent line breaks */
}

.call-status{
    width: 14% !important;
    min-width: 100px !important;
    color: white;
    /* padding: 1530px; */
  }

  .no-padding:hover {
    color: inherit !important; /* or specify the original color value */
  }
  
  .date-picker{
    color: #808080;
    margin-top: 2.9px;
    background-color: transparent;
    border-radius: 10px;
    width: 200px;
    height: 38px;
  }
  .calender-icon{
    color: #808080;
    margin-left: 9px ;
    margin-top: 6px;
}

.text-green{
    color: #ff5e00 !important;
    text-transform: capitalize ;
    font-size: 16px;
  font-weight: bolder !important
}
.modal-body{
    /* border-top: 1px solid #ff5e00 !important; */
    /* border-bottom: 1px solid #fa6e3bf5 !important; */
}
.css-p1u9s4{
padding-top: 0px !important;
}

.orange{
    color: #ff5e00 !important;
   font-size: 18px;

}

.card-project:hover {
    transform: scale(1.06);
    }
  .form-control:focus{
    box-shadow: 0 0 0 .08rem #2684FF !important;
  }

  .color-vodex{
    color: #ff5e00 !important;
    font-weight: bold;
    font-style: italic;

}
.table > thead > tr > th {
    font-size: 11.5px !important;
    font-weight: 800 !important;
    padding-bottom: 0;
    text-transform: capitalize;
    border: 0; }
.common-heading{
    font-weight:750;
    text-transform: capitalize;
    color: #635b5b;

}
.page-heading {
    color: #000 !important;
    /* background-color: #00c6b6; */
    font-size: 2em;
    /* font-weight: 600; */
    font-style: initial;
    letter-spacing:-0.5px !important;
  }

  .rounded-button-icon1{
    margin: 1px;
    background-color: #e9ecefda;
    padding: 7px 12px;
    /* border-radius: 5px !important; */
    color: #000;
    max-height: 38px;
    border-radius: 25%;

}
.rounded-button-icon1:hover {
    background-color: #c0c4c9da;
}
.z-index-high{
    z-index: 100 !important;
}

.otp-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-card-otp {
    width: 240px;
    height: 220px;
    border-radius: 1.2rem;
    background-color: #fff;
    color: #212121;
    text-align: center;
    position: relative;
  }

  .container-logout {
    position: relative;
  }

  .new-logout {
    position: absolute;
    top: 23px;
    right:23px;
    border-radius: 6px;
    padding: 8px 12px;
    cursor: pointer;
  }
 
  .new-logout i {
    font-size: 14px;
    text-align: center;
  }

  .agent-chat-input:focus {
    border: 1px solid #d17842 !important;
  }

  .voice-cloning-input:focus {
    border: 1px solid #CD853F !important;
  }

  .camp-btn-cont {
    margin-left: 30px;
    width: 350px;
    padding-right: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 123px;
    top: 10px;
  }
  
  .camp-btns-selected,
  .camp-btns {
    border-radius: 1.625rem;
    padding: 8px 15px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.5px;
    transition: background 0.3s ease, color 0.3s ease;
    box-sizing: border-box;
  }
  
  .camp-btns-selected {
    background: linear-gradient(to right, rgba(15, 12, 41, 0.884), rgba(48, 43, 99, 0.884), rgba(36, 36, 62, 0.884));
    color: white;
  }
  
  .camp-btns {
    color: #080008;
    background: transparent;
    margin: 0 10px; 
  }
  
  .camp-btns:hover {
    background: linear-gradient(to right, rgba(15, 12, 41, 0.884), rgba(48, 43, 99, 0.884), rgba(36, 36, 62, 0.884));
    color: white;
  }

  .outBound-arrow {
    margin:0 15px;
    border-radius: 100%;
    padding: 6px;
    background-color: rgba(118, 255, 118, 0.555);
    color: green;
    transform: rotate(135deg);
  }

  .inBound-arrow {
    margin:0 15px;
    border-radius: 100%;
    padding: 6px;
    background-color: rgba(255, 195, 85 ,0.555);
    color: orange;
    transform: rotate(135deg)
  }
  /* Health Check */
  .health-check-card-cont {
    background-color: #181818;
    z-index: 999;
    position: absolute;
    top: 25%;
    right: 0;
    border-radius: 8px 0 0 8px;
    transition: all 0.3s ease;
  }
  
  .arrow-icon {
      color: whitesmoke;
      font-weight: bold;
      animation: backAndForth 1s ease-in-out infinite;
      cursor: pointer;
  }
  
  .main-cont {
    margin: 22px;
  }
  .conttt {
    display: flex;
  }
  .healthcheck-logo {
    width: 24px;
    height: 24px;
    padding: 5px 7px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .healthcheck-success {
    background: #00B050;
  }
  .healthcheck-fail {
    background: #D72917;
  }
  .fas-health-check {
    color: #FFFFFF;
  }
  .health-close {
    cursor: pointer;
  }
  .healthcheck-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
    letter-spacing: 0.3px;
  }
  .healthcheck-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 10px;
    text-align: left;
    color: #FFFFFF;
    letter-spacing: 0.3px;
  }
  .health-availability-btn {
    position: absolute;
    top: 100px;
    right: 22px;
    width: 160px;
    height: 40px;
    padding: 8px 14px;
    border-radius: 8px;
    background: #363D6D;
    border: 1px solid #3C4479;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    letter-spacing: 0.3px;
    opacity: 0;
    animation: fadeIn 0.3s ease 0.3s forwards;
  }

  .fix-container {
    min-height:250px; 
    margin: 30px;
  }

  .fix-text-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .fix-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .fix-btn {
    width: 150px;
    height: 43px;
    border-radius: 8px;
    border: 1px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    background-color:#3c447996;
    color: white;
  }
 .disable-fix-btn {
    width: 150px;
    height: 43px;
    border-radius: 8px;
    border: 1px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    background-color:#3c447996;
    color: white;
  }

  .fix-btn:hover {
    background-color:#363D6D;
    transition: all 0.1s ease-in-out;
  }

  .fix-required-star {
    color: #D72917;
    margin: 10px 0;
  }

  .fix-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
  }

  .modal-body-cont{
    border-top: none !important;
    margin: auto;
    width: 100%;
  }

  .btn-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .queue-btn {
    width: 100px;
    margin:0 2px;
    padding: 2px 5px;
    font-size: 13px;
    font-weight: bold;
    background-color: #595550;
    border-radius: 3px;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    }
    
  .queue-container {
    margin-top: 13px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    }

  .emergency {
      background-color: rgb(221, 34, 34);
    }
  
  .toggle-label {
      color: #9A9A9A;
      padding: 0 10px;
      font-weight: bold;
      font-size: 13px;
  }

  /* CallHistory */
  .date-picker {
    margin-top: 0 !important;
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    padding: 0.4rem 0 0 0.5rem !important;
}


.react-datepicker__input-container input {
  outline: none;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 35px !important;
}

/* Call History */
.custom-clear-btn {
  background:transparent;
  border: 1px solid #B2BEB5;
  padding: 7px 13px;
  border-radius: 10px;
  cursor: pointer;
  height: 100%;
  cursor: pointer;
}
.custom-clear-btn:hover {
  background-color: #c2c2c2;
}

.custom-clear-select {
  background: transparent;
  border: 1px solid #B2BEB5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  height: 38px;
}
.call-history-button {
  border: none;
  outline: none;
  padding: 6px 18px;
  background: #fe5d00d8;
  border-radius: 10px;
  letter-spacing: 0.3px;
  color: whitesmoke;
}
.call-history-button:hover {
  background: #FE5E00;
  transition: all 0.2s ease-in-out;
}

    /* Animations HealthCheck*/
    /* Button */
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  
    @keyframes backAndForth {
      0%, 100% {
        transform: translateX(0);
      }
      50% {
        transform: translateX(-3px); /* Adjust the distance as needed */
      }
    }


    /* New Project CSS */
    .cmn-button {
      border-radius: 8px;
      padding: 8px 12px;
      font-size: 13px;
      font-weight: 500;
      color: #FFFFFF;
      border: none;
      outline: none;
      cursor: pointer;
      background: #FF5E00BF;
      transition: background-color 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cmn-button:hover {
      background-color: #E65500;
    }
    .project-button {
      border: 1px solid #D1D9EF;
      font-size: 11px;
      color: #555555;
      border-radius: 8px;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;
    }
    .common-button-layout {
      padding: 8px 12px;
      border: 1px solid #CCD2DE;
      border-radius: 8px;
      transition: all 0.3s ease;
    }
    .common-button-layout:hover {
      background-color: #E0E0E0;
    }
    .rounded-bg {
      width: 35px; 
      height: 35px; 
      border-radius: 50%;
      display: flex; 
      align-items: center; 
      justify-content: center; 
      background-color: #F1F4FA;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    .rounded-bg:hover {
      background-color: #D6DAE3;
    }

    .new-common-heading {
      font-size: 12px;
      font-weight: 600;
      color: #0A0A0A;
    }

    /* .modal-dialog {
      max-width: 750px;
    } */
    .modal-content {
      border-radius: 8px !important;
    }

    .modal-cross-icon {
      width: 24px;
      height: 24px;
      background-color: #E7EAF1;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .new-modal-header {
      padding: 1.1rem 2rem !important;
      background-color: #F1F4FA; 
      border: none
    }
    .new-modal-body {
      border: none !important;
      padding: 1rem 2rem !important;
    }
    .new-modal-footer {
      padding: 0.75rem 1rem !important ;
      border: none !important;
    }
    .placeholder-ip {
      position: relative;
      display: inline-block;
    }
    .input-container {
      position: relative;
    }
    .custom-placeholder {
      position: absolute;
      top: 48%;
      left: 14px;
      font-size: 13px;
      transform: translateY(-50%);
      color: #555555;
      pointer-events: none; /* Allows clicks to pass through */
      z-index: 9999;
    }
    .common-ip {
      width: 100%;
      border: 1px solid #CCD2DE;
      border-radius: 8px;
      padding: 10px 13px;
      outline: none;
      font-size: 14px;
    }
    .common-ip:focus {
      outline: 1px solid #CCD2DE;
    }
    .common-ip::placeholder {
      color: #555555;
      font-size: 13px;
      font-weight: 400;
    }
    
    .font-14-600-grey {
      font-size: 14 !important; 
      font-weight: 600 !important;
       color: #181818 !important;
    }
    .font-14-grey {
      font-weight: 500  !important;
      font-size: 14px !important; 
      color: #181818 !important;
    }
    .font-12-grey {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #181818 !important;
    }
    .font-12-lightgrey {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #555555 !important;
    }
    .font-12-mediumgrey {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #333333 !important;
    }
    .font-13-lightgrey {
      font-size: 13px !important;
      font-weight: 500 !important;
      color: #555555 !important;
    }
    .font-13-darkgrey {
      font-size: 13px !important;
      font-weight: 500 !important;
      color: #333333 !important;
    }
    .font-14-lightgrey {
      font-size: 14px !important; 
      font-weight: 500 !important;
      color: #555555 !important;
    }
    .font-18-grey {
      font-size: 18px;
      font-family: 500;
      color: #181818;
    }
    .font-20-grey {
      font-size: 20px !important; 
      font-weight: 600 !important;  
      color: #181818 !important;
    }
    .font-20-500-lightgrey {
      font-size: 20px; 
      font-weight: 500; 
      color: #555555;
    }

    .font-20-700-lightgrey {
      color: #55555599;
      font-weight: 700;
      font-size: 20px;
    }

    /* Date Picker */
    .new-date-picker {
      width: 100%;
      border: 1px solid #CCD2DE;
      border-radius: 8px;
      padding: 4px 3px;
    }   
    .custom-date-input {
      width: 100%;
      padding: 8px 45px 8px 12px;
      border-radius: 8px;
      font-size: 13px;
      color: #555555;
      outline: none;
    }    
    .react-datepicker-popper {
      z-index: 9999;
    }
    .datepicker-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #555555;
      pointer-events: none;
    }
    .calendar-icon {
      font-size: 14px;
    }
    .check-label {
        margin: 0; 
        padding: 0; 
        display: inline; 
    }
    .custm-ip {
      font-size: 13px;
    }
    .custm-ip::placeholder {
      font-size: 13px;
      font-weight: 400;
      color: #9E9FA1;
    }
  
    /* Common Flex Properties */
    .flexOnly {
      display: flex;
    }
    .flex-row {
      display: flex;
      flex-direction: row;
    }
    .flex-column {
      display: flex;
      flex-direction: column;
    }
    .flex-align-center {
      display: flex;
      align-items: center;
    }
    .flex-row-center {
      display: flex;
      justify-content: center;
    }
    .flex-xy-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .flex-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .flex-evenly {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
    .flex-around {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    /* Acc History */
    .acc-history-cmn-button {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: #181818;
      font-size: 14px;
      font-weight: 500;
      height: "43px",
    }

    .call-custom-placeholder {
      position: absolute;
      top: 50%;
      left: 15px;
      font-size: 13px;
      transform: translateY(-50%);
      color: #555555;
      pointer-events: none;
      z-index: 9999;
    }

    .flag-dropdown  {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
.spent-history-table,.recharge-history-table {
  @media (max-width: 1400px) {
    .pagination-container {
      position: relative;
      padding-bottom: 60px;
    }

    .pagination {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: max-content;
      bottom: 10px;
    }
  }
}
.project-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Spreadsheet {
  width: 100%;
}
.Spreadsheet__table {
  width: 100%;
  table-layout: auto !important;
}

.spreadsheet-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent; 
}

.spreadsheet-container::-webkit-scrollbar {
  height: 3px; 
}
