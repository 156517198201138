.tableRightCol{
    text-align: center  ;
}
.toolTip{
display: inline;
color: red !important;
}
.no-padding {
    padding:7px 10px !important;
    max-height: 32px !important;
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Display ellipsis (...) for overflow text */
    white-space: nowrap; /* Prevent line breaks */
}

.call-status{
    width: 14% !important;
    min-width: 100px !important;
    color: white;
    /* padding: 1530px; */
  }

  .no-padding:hover {
    color: inherit !important; /* or specify the original color value */
  }

  /* Variation of work by @mrhyddenn for Radios */


.check {
  cursor: pointer;
  position: relative;
  margin: auto;
  width: 18px;
  height: 18px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.check:before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(34, 50, 84, 0.03);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #c8ccd4;
  stroke-width: 1.5;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
}

.check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}

.check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}

.check:hover:before {
  opacity: 1;
}

.check:hover svg {
  stroke: var(--accent-color, #a3e583);
}

#cbx2:checked + .check svg {
  stroke: var(--accent-color, #a3e583);
}

#cbx2:checked + .check svg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear;
}

#cbx2:checked + .check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}