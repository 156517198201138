



.form-onboarding {
    background: #0c0f14;
    box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
      0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09),
      0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    position: relative;
    border-radius: 18px;
  }
  .form-onboarding .label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: -moz-fit-content;
    height: fit-content;
  }
  .form-onboarding .label:has(input:focus) .title {
    top: 0;
    left: 0;
    color: #d17842;
  }
  .onboard-title{
    padding: 0 10px;
    transition: all 300ms;
    font-size: 14px;
    color:#d17842 ;
    font-weight: bolder;
    width: -moz-fit-content;
    width: fit-content;
    top: 14px;
    position: relative;
    left: 2px;
    background: #0c0f14;
  }
  .form-onboarding .label .title {
    padding: 0 10px;
    transition: all 300ms;
    font-size: 12px;
    color: #8b8e98;
    font-weight: 600;
    width: -moz-fit-content;
    width: fit-content;
    top: 14px;
    position: relative;
    left: 15px;
    background: #0c0f14;
  }
  .form-onboarding .input-field {
    width: auto;
    height: 50px;
    text-indent: 15px;
    border-radius: 15px;
    outline: none;
    background-color: transparent;
    border: 1px solid #21262e;
    transition: all 0.3s;
    caret-color: #d17842;
    color: #aeaeae;
  }
  
  .form-onboarding .input-field:hover {
    border-color: rgba(209, 121, 66, 0.5);
  }
  
  .form-onboarding .input-field:focus {
    border-color: #d17842;
  }
  .form-onboarding .split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
  }
  .form-onboarding .split label {
    width: 230px;
  }
  .form-onboarding .checkout-btn {
    margin-top: 20px;
    padding: 8px 0;
    border-radius: 25px;
    font-weight: 700;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    border: none;
    justify-content: center;
    fill: #fff;
    color: #fff;
    border: 2px solid transparent;
    background: #d17842;
    transition: all 200ms;
  }
  .form-onboarding .checkout-btn:active {
    scale: 0.95;
  }
  
  .form-onboarding .checkout-btn:hover {
    color: #d17842;
    border: 2px solid #d17842;
    background: transparent;
  }
  
  .checkbox-label{
    font-size: 12px;
    font-weight: 600;
    color: #8b8e98 !important;
    margin-left: 3px ;

  }
.modal-content{
  border-radius: 20px;
}

.color-white{
  color: #fff !important;
}