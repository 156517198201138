
  .floating-widget1 {
    position: fixed;
    top: 13%; 
    right: 40px; 
    color: #fff;
    padding: 10px;
    border-radius: 2%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 15;
  }
  .floating-widget1  i{
    
    font-size: 15px;
    cursor: pointer;
    color: black;
  }
  .modal-video {
    height:280px !important;
    border-radius: 50px;
  }
  

  /* style={{backgroundColor: "transparent", border: "1px solid gray", borderRadius:"10px", display:"flex", alignItems:"center"}}
   */
/* **Serch bar css Start */
.form1 {
  --padding: 1.5em;
  --rotate: 80deg;
  --gap: 1.5em;
  --icon-change-color: #15A986;
  --height: 37px;
  width: 220px;
  padding-inline-end: 1em;
  position: relative;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #B2BEB5	;
  border-radius: 10px;
  margin-bottom: 0;
}

.form1 label {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--height);
  margin-bottom: 0 !important;
}

.form1 input {
  width: 100%;
  padding-inline-start: calc(var(--padding) + var(--gap));
  outline: none;
  background: none;
  border: 0;
  margin-top: 3px;
}
.form1 input::placeholder {
 color: #808080;
 font-size: 13px;
}
.form1 svg {
  color: #808080;
  transition: 0.3s cubic-bezier(.4,0,.2,1);
  position: absolute;
  height: 17px;
}
.icon {
  position: absolute;
  left: var(--padding);
  transition: 0.3s cubic-bezier(.4,0,.2,1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.swap-off {
  transform: rotate(-80deg);
  opacity: 0;
  visibility: hidden;
}
.close-btn {
  background: none;
  border: none;
  right: calc(var(--padding) - var(--gap));
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.3s;
  opacity: 0;
  transform: scale(0);
  visibility: hidden;
}

.form1 input:focus ~ .icon {
  transform: rotate(var(--rotate)) scale(1.3);
}

.form1 input:focus ~ .icon .swap-off {
  opacity: 1;
  transform: rotate(-80deg);
  visibility: visible;
  color: var(--icon-change-color);
}

.form1 input:focus ~ .icon .swap-on {
  opacity: 0;
  visibility: visible;
}

.form1 input:valid ~ .icon {
  transform: scale(1.3) rotate(var(--rotate))
}

.form1 input:valid ~ .icon .swap-off {
  opacity: 1;
  visibility: visible;
  color: var(--icon-change-color);
}

.form1 input:valid ~ .icon .swap-on {
  opacity: 0;
  visibility: visible;
}

.form1 input:valid ~ .close-btn {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: 0s;
}



.button-delete {
  margin-top: 0px;
  height: 32px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.button-delete:hover {
  transition-duration: 0.3s;
  align-items: center;
  gap: 0;
}

.bin-top {
  transform-origin: bottom right;
}
.button-delete:hover .bin-top {
  transition-duration: 0.5s;
  transform: rotate(160deg);
}


@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}

.bin-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(255, 85, 85);
  cursor: pointer;
  border: 2px solid rgb(255, 201, 201);
  transition-duration: 0.3s;
  position: relative;
  overflow: hidden;
}
.bin-bottom {
  width: 10px;
  z-index: 2;
}
.bin-top {
  width: 12px;
  transform-origin: right;
  transition-duration: 0.3s;
  z-index: 2;
}
.bin-button:hover .bin-top {
  transform: rotate(45deg);
}
.bin-button:hover {
  background-color:  rgb(255, 75, 75);
}
.bin-button:active {
  transform: scale(0.9);
}
.garbage {
  position: absolute;
  width: 14px;
  height: auto;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}
.bin-button:hover .garbage {
  animation: throw 0.4s linear;
}
@keyframes throw {
  from {
    transform: translate(-400%, -700%);
    opacity: 0;
  }
  to {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}


.use-button {
  background: transparent;
  position: relative;
  padding: 5px 18px;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgb(255,0,0);
  border-radius: 25px;
  outline: none;
  overflow: hidden;
  color: rgb(255, 0, 0);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
}

.use-button span {
  margin: 10px;
}

.use-button::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: '';
  border-radius: 50%;
  display: block;
  width: 20em;
  height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.5s ease-out;
  z-index: -1;
}

.use-button:hover {
  color: #fff;
  border: 1px solid rgb(255,0,0);
}
.use-button-dash:hover {
  color: #fff;
  border: 1px solid rgb(255,0,0);
  transform: scale(1.06);
}



.use-button:hover::before {
  box-shadow: inset 0 0 0 10em rgb(255, 95, 95);
}

/* Add button start */

.add-button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
 }
 
 .add-button.learn-more {
  width: 12rem;
  height: auto;
 }
 
 .add-button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background:linear-gradient(to right, rgb(15, 12, 41), rgb(48, 43, 99), rgb(36, 36, 62));
  border-radius: 1.625rem;
 }
 
 .add-button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
 }
 
 .add-button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
 }
 
 .add-button.learn-more .circle .icon.arrow::before {
  position: absolute;
  /* content: ""; */
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
 }
 
 .add-button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: linear-gradient(to right, rgb(15, 12, 41), rgb(48, 43, 99), rgb(36, 36, 62));
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: capitalize;
 }
 
 .add-button:hover .circle {
  width: 100%;
 }
 
 .add-button:hover .circle .icon.arrow {
  /* background: #fff; */
  transform: translate(1rem, 0);
 }
 
 .add-button:hover .button-text {
  color: #fff;
 }


 /* Add button end */

 /* // Save button startt? */

.button {
  text-decoration: none;
  line-height: 1;
  border-radius: 0.8rem;
  overflow: hidden;
  position: relative;
  box-shadow: 10px 10px 20px rgba(0,0,0,.05);
  background-color: #fff;
  color: #121212;
  border: none;
  cursor: pointer;
}

.button-decor {
  position: absolute;
  inset: 0;
  background-color: #00ad54;
  transform: translateX(-100%);
  transition: transform .3s;
  z-index: 0;
}

.button-content {
  display: flex;
  align-items: center;
  font-weight: 600;
  position: relative;
  overflow: hidden;
}

.button__icon {
  width: 48px;
  height: 40px;
  background-color: #00ad54;
  display: grid;
  place-items: center;
}

.button__text {
  display: inline-block;
  transition: color .2s;
  padding: 2px 1.5rem 2px;
  padding-left: .75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.button:hover .button__text {
  color: #fff;
}

.button:hover .button-decor {
  transform: translate(0);
}

 /* // Save button End? */

/* Star check box css start */

.hidden-label svg {
  cursor: pointer;
}

.hidden-checkbox {
  display: none;
}
.hidden-checkbox:checked + .hidden-label svg {
  fill: #3E89F7;
  animation: pop_42 0.5s ease-out;
}
.hidden-checkbox:checked + .hidden-label svg path{
  stroke: none;
}

@keyframes pop_42 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

/* Star check box css End */


/* **Favorites Button */

.use-button1 {
  background: transparent;
  position: relative;
  padding: 5px 18px;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgb(255,0,0);
  border-radius: 7px;
  outline: none;
  overflow: hidden;
  color: rgb(255, 0, 0);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
  height: 36px !important;
  top: 2.5px;
  left: 4px;
}

.use-button1 span {
  margin: 10px;
}

.use-button1::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: '';
  border-radius: 50%;
  display: block;
  width: 20em;
  height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.5s ease-out;
  z-index: -1;
}

.use-button1:hover {
  color: #fff;
  border: 1px solid rgb(255,0,0);
}

.use-button1:hover::before {
  box-shadow: inset 0 0 0 10em rgb(255, 95, 95);
}


/* bounce loader */

/* .bounce-wrapper {
  width: 200px;
  height: 60px;
  position: relative;
  z-index: 1;
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #000;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 .5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: .2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.bounce-shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 .5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
}

.bounce-shadow:nth-child(4) {
  left: 45%;
  animation-delay: .2s
}

.bounce-shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
} */


/* Verification input start */


.verification-input {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  margin: 1em;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: rgba(182, 181, 181, 0.945);
  box-shadow: inset 3px 3px 6px #d1d1d1,
            inset -3px -3px 6px #ffffff;
  /* top: 6.5em; */
  /* left: 1.5em; */
  padding-left: 15px;
  transition: .4s ease-in-out;

  
}

.verification-input:hover {
  box-shadow: inset 0px 0px 0px #d1d1d1,
            inset 0px 0px 0px #ffffff;
  background-color: lightgrey;
}

.verification-input:focus {
  box-shadow: inset 0px 0px 0px #d1d1d1,
            inset 0px 0px 0px #ffffff;
  background-color: lightgrey;
}


/* ****************************Design***************** */



.form-card {
  width: 240px;
  height: 200px;
  border-radius: 1.2rem;
  background-color: #fff;
  /* padding: 1.3rem; */
  color: #212121;
  text-align: center;
  position: relative;
}

.form-card-title {
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
  margin-top: 0.2rem;
}

.form-card-prompt {
  margin-bottom: 2rem;
  font-size: 14px;
}
/* hard reset */
.form-card-input {
  all: unset;
}

.form-card-input-wrapper {
  position: relative;
  width: 100%;
  height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
}

.form-card-input {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 2rem;
  text-align: start;
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
  position: absolute;
  z-index: 3;
  background-color: transparent;
}

.form-card-input-bg {
  content: '';
  width: 240px;
  height: 60px;
  margin: auto;
  inset: 0;
  bottom: 10px;
  position: absolute;
  z-index: 1;
  border-radius: 12px;
  background-color: rgba(206, 206, 206, 0.664);
}

.call-again {
  color: #5e5e5e;
  font-size: 14px;
}

.call-again:hover {
  cursor: pointer;
}

.underlined {
  text-decoration: underline;
}

.form-card-submit {
  position: absolute;
  width: 180px;
  margin: auto;
  color: white;
  border: none;
  background-color: #212121;
  font-size: 1.2rem;
  border-radius: 0.8rem;
  padding: 0.8rem 3.5rem;
  bottom: 2rem;
  left: 0;
  right: 0;
  -webkit-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}

.form-card-submit:hover {
  cursor: pointer;
  opacity: 0.8;
}

.form-card-submit:active {
  opacity: 0.9;
  -webkit-transform: scale(95%);
  -ms-transform: scale(95%);
  transform: scale(95%);
}

#uuid-d8a0d861-3741-4013 {
  width: auto;
  height: 500px;
}

@-webkit-keyframes scale-out {
  0%, 15%, 100% {
    color: #fff;
    -webkit-transform: scale(72%);
    transform: scale(72%);
  }

  16%, 98% {
    color: rgba(255, 255, 255, 0.295);
    -webkit-transform: scale(100%);
    transform: scale(100%);
  }
}

@keyframes scale-out {
  0%, 15%, 100% {
    color: #fff;
    -webkit-transform: scale(72%);
    transform: scale(72%);
  }

  16%, 98% {
    color: rgba(255, 255, 255, 0.295);
    -webkit-transform: scale(100%);
    transform: scale(100%);
  }
}

@-webkit-keyframes shine {
  to {
    background-position: 200% center;
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}


.spinner-square {
  display: flex;
  flex-direction: row;
  width: 40px;
  z-index: 9999 !important;
}

.spinner-square > .square {
  width: 6px;
  height: 20px;
  margin: auto;
  border-radius: 4px;
  position:relative;
  z-index: 9999 !important;
}

.square-1 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
}

.square-2 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 100ms infinite;
}

.square-3 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms infinite;
}
.square-4 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 300ms infinite;
}
.square-5 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms infinite;
}

@keyframes square-anim {
  0% {
      height: 40%;
  }
  20% {
      height: 40%;
  }
  40% {
      height: 100%;
  }
  80% {
      height: 40%;
  }
  100% {
      height: 40%;
  }
}